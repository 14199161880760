@import "/styles/global/variables.scss";
@import "/styles/global/mixins.scss";

//TODO: check responsive around tablet breakpoints to see if illustrations don't go over elements

.container {
  position: relative;
  padding-top: 100px;
  padding-bottom: 100px;
  @include media-down(md) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  &_background {
    &-primary {
      background: $color-light;
      color: $color-dark;
    }
    &-secondary {
      background: $color-secondary;
      color: $color-light;
    }
  }
  &_withTitle {
    padding-top: 96px;
    @include media-down(md) {
      padding-top: 40px;
    }
  }
  &_long {
    margin-bottom: 40px;
    padding-bottom: 150px;
    @media print {
      padding-bottom: 40px;
    }
    @include media-down(md) {
      padding-bottom: 40px;
      margin-bottom: 0px;
    }
  }
  @media print {
    page-break-before: always;
    margin-top: 0;
    padding-top: 0;
  }
}

.title {
  margin-bottom: 86px;
  padding-bottom: 14px;
  position: relative;
  @media print {
    page-break-inside: avoid;
  }
  @include media-down(md) {
    margin-bottom: 64px;
  }

  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    height: 10px;
    width: 100px;
    border-radius: 20px;
  }
  &_position {
    &-center {
      text-align: center;
      &::after {
        left: 50%;
        transform: translateX(-50%);
      }
    }
    &-padding-left {
      margin-left: calc(1 / 12 * 100%);
      @include media-down(md) {
        margin-left: 0;
      }
    }
  }
  &_primary {
    &::after {
      background-color: $color-variant-6-light;
    }
  }
  &_secondary {
    &::after {
      background-color: $color-primary;
    }
  }
}

.character {
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: flex-end;
  &_position {
    &-left {
      left: -22px;
    }
    &-right {
      right: -22px;
      transform: scalex(-1);
    }
  }
  @include media-down(md) {
    display: none;
  }
}

.decorator {
  position: absolute;
  bottom: 0;
  z-index: 900;
  &_type {
    &-1 {
      width: 65px;
      height: 320px;
      transform: translateY(50%);
      left: 38px;
    }
    &-2 {
      width: 70px;
      height: 234px;
      transform: translateY(50%);
      left: 52px;
    }
    &-3 {
      width: 430px;
      height: 150px;
      transform: translateY(62%);
      right: -40px;
    }
  }
  @include media-down(md) {
    display: none;
  }
}
