@import "/styles/global/variables.scss";
@import "/styles/global/mixins.scss";

.container {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.size {
  margin-top: 8px;
}
.button {
  margin-top: 32px;
}
