// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------

@import "./variables.scss";

// Apply CSS to screens larger than the breakpoint size
@mixin media-up($breakpoint) {
  @media (min-width: map-get($grid-breakpoints, $breakpoint)) {
    @content;
  }
}

// Apply CSS to screens below the breakpoint size
@mixin media-down($breakpoint) {
  @media (max-width: (map-get($grid-breakpoints, $breakpoint) - 1)) {
    @content;
  }
}

@mixin clamp-text-line($nb-lines) {
  display: -webkit-box;
  -webkit-line-clamp: $nb-lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}

// TODO Avoid using these
@mixin grid($nb-columns) {
  @each $breakpoint, $column-width in $columns-widths {
    @include media-up($breakpoint) {
      width: $nb-columns * $column-width + ($nb-columns - 1) *
        $grid-gutter-width;
    }
  }
}

@mixin offset($nb-columns) {
  margin-left: calc(#{$nb-columns} / #{$grid-columns} * 100%);
}

// TODO implement this where it isn't
@mixin set-grid() {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: $grid-gutter-width;
  @include media-down(sm) {
    column-gap: 0;
  }
}

@mixin safari-only() {
  @media screen and (min-color-index: 0) and (-webkit-min-device-pixel-ratio: 0) {
    @content;
  }
}
