@import "/styles/global/variables.scss";
@import "/styles/global/mixins.scss";

.progressBarContainer {
  z-index: 1000;
  position: fixed;
  margin-left: $navigation-panel-width;
  height: $progress-bar-height;
  width: calc(100% - #{$navigation-panel-width});
  background-color: $color-variant-6-light;
  @include media-down(lg) {
    margin-left: 0;
    width: 100%;
  }
  @media print {
    display: none;
  }
}

.progressBar {
  max-width: 100%;
  height: 100%;
  background-color: $color-variant-1;
}
