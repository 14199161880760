@import "/styles/global/variables.scss";
@import "/styles/global/mixins.scss";

.container {
  margin-top: 150px;
  display: flex;
  position: relative;
  width: 100%;
  @include media-down(lg) {
    margin-top: 70px;
  }
  @media print {
    display: none;
  }
}

.subContainer {
  @include set-grid();
  margin-bottom: 248px;
  @include media-down(lg) {
    margin-bottom: 70px;
  }
}
.nav_left {
  grid-column: 1/6;
  @include media-down(xxl) {
    grid-column: 1/7;
  }
  @include media-down(xl) {
    grid-column: 2/12;
  }
  @include media-down(md) {
    grid-column: 1/13;
  }
}
.nav_right {
  grid-column: 8/13;
  @include media-down(xxl) {
    grid-column: 7/13;
  }
  @include media-down(xl) {
    grid-column: 2/12;
  }
  @include media-down(md) {
    grid-column: 1/13;
  }
}
.picto {
  display: flex;
  position: absolute;
  bottom: 0;
  right: -22px;
  @include media-down(lg) {
    display: none;
  }
}
