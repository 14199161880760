@import "/styles/global/variables.scss";
@import "/styles/global/mixins.scss";

$scrollbar-width: 13px;

$mobile-download-container-height: 132px;
$mobile-socials-container-height: 87px;

.container {
  z-index: 1000;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  top: $menu-top-height;
  height: calc(100% - #{$menu-top-height});
  width: $navigation-panel-width;
  background-color: $color-primary;
  transition: transform 0.4s ease-in-out;
  @include media-down(lg) {
    transform: translateY(100%);
    width: 100%;
    align-items: center;
    top: unset;
    bottom: 0;
  }
  &_displayMobile {
    @include media-down(lg) {
      transform: translateY(0);
    }
  }
  a {
    text-decoration: none;
  }
  @media print {
    display: none;
  }
}
.menuWrap {
  position: relative;
  overflow-y: hidden;
  min-height: calc(
    100% - $mobile-download-container-height - $mobile-socials-container-height
  );
  @include media-down(md) {
    width: calc(100% - 20px);
    align-self: flex-end;
  }
}
.menuContainer {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  margin-left: 30px;
  overflow-y: auto;
  height: 100%;
  padding-right: 20px;
  @include media-down(lg) {
    margin-left: 0;
  }
}

.menuContainer::before {
  left: 0;
  top: 0;
  position: absolute;
  content: "";
  height: 60px;
  width: calc(100% - #{$scrollbar-width});
  background: linear-gradient(
    to bottom,
    rgba($color-primary, 1) 0%,
    rgba(white, 0) 100%
  );
  pointer-events: none;
}
@include safari-only {
  .menuContainer::before,
  .menuContainer::after {
    display: none;
  }
}

.menuContainer::after {
  left: 0;
  bottom: 0;
  position: absolute;
  content: " ";
  height: 60px;
  width: calc(100% - #{$scrollbar-width});
  background: linear-gradient(
    to bottom,
    rgb(white, 0) 0%,
    rgba($color-primary, 1) 100%
  );
  pointer-events: none;
}

.menuContainer::-webkit-scrollbar {
  width: $scrollbar-width;
}

.menuContainer::-webkit-scrollbar-track {
  margin-top: 10px;
  margin-bottom: 10px;
  background: transparent;
  border-right: 5px transparent solid;
  background-clip: padding-box;
}

.menuContainer::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-right: 5px transparent solid;
  background-clip: padding-box;
}
.menuContainer:hover {
  &::-webkit-scrollbar-track {
    background-color: $color-primary-light;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba($color-light, 0.4);
  }
}

.menuSegment {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  &:first-of-type {
    padding-top: 60px;
  }
  &:last-of-type {
    padding-bottom: 60px;
  }
  li {
    color: $color-light;
  }
}

.page {
  &_current {
    &::before {
      content: "\2024";
      margin-right: 16px;
    }
  }
}

.navigationLink {
  display: flex;
  align-self: flex-start;
  text-decoration: none;
  transition: opacity 0.4s ease-in-out;
  &:hover {
    opacity: 0.7;
  }
}

.subMenu {
  display: none;
  flex-direction: column;
  row-gap: 16px;
  border-left: 2px solid $color-light;
  padding-left: 20px;
  padding-right: 20px;
}

.subMenuActive {
  display: flex;
}

.subMenuLine {
  height: 100%;
  width: 2px;
  background-color: $color-light;
}

.chapter {
  opacity: 0.7;
  transition: opacity 0.2s;
  &_reached {
    opacity: 1;
  }
}

.downloadContainer {
  background: #ffffff4d;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding: 30px;
  @include media-down(lg) {
    padding-top: 15px;
    padding-bottom: 15px;
    width: 100%;
    align-items: center;
    height: $mobile-download-container-height;
  }
  @media (max-height: 480px) {
    display: none;
  }
}

.socials {
  display: none;
  @include media-down(lg) {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 15px 0;
    background-color: $color-secondary;
    height: $mobile-socials-container-height;
  }
}

.button {
  width: fit-content;
}
