@import "/styles/global/variables.scss";
@import "/styles/global/mixins.scss";

.container {
  z-index: 1100;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: $menu-top-height;
  background-color: $color-secondary;
  @media print {
    position: relative;
  }
}

.subContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  position: relative;
  margin-left: 30px;
  height: 60px;
  width: 187px;
  transition-property: opacity, margin-left, height, width;
  transition-duration: 0.4s;
  transition-timing-function: ease-in-out;
  &:hover {
    opacity: 0.6;
  }
  @include media-down(xs) {
    margin-left: 8px;
  }
}

.separator {
  background-color: $color-light;
  width: 2px;
  height: 60px;
  opacity: 0.5;
  margin: 0 48px 0 82px;
  @include media-down(lg) {
    display: none;
  }
}

.title {
  padding-right: 48px;
  @include media-down(lg) {
    display: none;
  }
  @media print {
    display: block;
    padding-left: 40px;
  }
}
.socials {
  margin-right: 120px;
  @include media-down(lg) {
    display: none;
  }
}

.menuButton {
  border: none;
  background: none;
  display: none;
  @include media-down(lg) {
    display: flex;
  }
  @media print {
    display: none;
  }
}

.burger {
  position: relative;
  height: 18px;
  width: 24px;
  margin-right: 30px;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 6px;
  transition: margin-right 0.4s ease-in-out;
  @include media-down(xs) {
    margin-right: 8px;
  }
}

.burgerDiv {
  display: block;
  position: absolute;
  height: 2px;
  width: 24px;
  border-radius: 2px;
  background-color: $color-light;
  transition: transform 0.4s ease-in-out, opacity 0.3s ease-in-out;
}

.burgerFirst {
  top: 0;
  &_active {
    top: 8px;
    transform: rotate(45deg);
  }
}

.burgerSecond {
  top: 8px;
  &_hidden {
    opacity: 0;
  }
}

.burgerThird {
  top: 16px;
  &_active {
    top: 8px;
    transform: rotate(-45deg);
  }
}
