@import "/styles/global/variables.scss";
@import "/styles/global/mixins.scss";

.container {
  display: flex;
  justify-content: space-between;
  border-radius: 20px;
  column-gap: 24px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  &_primary {
    background-color: $color-primary;
  }
  &_secondary {
    background-color: $color-secondary;
  }
  &_reverse {
    flex-direction: row-reverse;
  }
}

.contentWrapper {
  display: flex;
  align-self: center;
  margin-left: 30px;
  row-gap: 6px;

  @media screen and (max-width: 485px) {
    margin-left: 20px;
  }
  &_reverse {
    margin-left: 0;
    margin-right: 20px;
    @media screen and (max-width: 485px) {
      margin-right: 10px;
    }
  }
}

.link {
  text-decoration: none;
  &_primary {
    &:hover {
      .iconWrapper {
        background-color: $color-primary-light;
        border-color: $color-primary;
        color: $color-light;
      }
    }
  }
  &_secondary {
    &:hover {
      .iconWrapper {
        background-color: $color-variant-4-light;
        color: $color-light;
      }
    }
  }
}

.iconWrapper {
  display: flex;
  align-items: center;
  border: 4px solid $color-dark;
  border-radius: 20px;
  padding: 30px;
  background-color: $color-light;
  transition: color 0.3s, border-color 0.3s, background-color 0.3s;
  &_primary {
    color: $color-primary;
    border-color: $color-primary-light;
  }
  &_secondary {
    color: $color-secondary;
    border-color: $color-secondary;
  }
}

.icon {
  font-size: 45px;
}

.text span {
  @include media-down(xxl) {
    font-size: 1rem;
  }
  @include media-down(xxl) {
    font-size: 1rem;
  }
  @media screen and (max-width: 485px) {
    font-size: 0.9rem;
  }
}
