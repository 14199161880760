@import "/styles/global/variables.scss";
@import "/styles/global/mixins.scss";

.button {
  display: flex;
  max-height: 50px;
  max-width: 280px;
  column-gap: 20px;
  border: 2px solid transparent;
  border-radius: 30px;
  align-items: center;
  transition: background-color 0.2s, border-color 0.2s, color 0.2s;
  text-decoration: none;
  &:hover {
    cursor: pointer;
  }
  &_withText {
    padding: 16px 20px 16px 20px;
  }
  &_withIcon {
    padding: 6px;
  }
  &_withTextAndIcon {
    padding: 4px 4px 4px 20px;
  }
}

.label {
  text-align: center;
}

.iconContainer {
  display: flex;
  padding: 10px;
  background-color: $color-light;
  border-radius: 100%;
}

.icon {
  font-size: 20px;
  &_only {
    font-size: 16px;
  }
}

@mixin colored-style($label, $color) {
  .button {
    &:hover {
      background-color: transparent;
      .label_#{$label} {
        color: $color;
      }
      .iconContainer {
        background-color: transparent;
      }
      &:hover {
        .icon_only {
          color: inherit;
        }
      }
    }
    &.color_#{$label} {
      background-color: $color;
      border-color: $color;
      &:hover {
        color: $color;
      }
    }
    .icon_#{$label} {
      color: $color;
    }
    .icon_only {
      color: $color-light;
    }
  }
}

@include colored-style(primary, $color-primary);
@include colored-style(secondary, $color-secondary);
@include colored-style(variant-1, $color-variant-1);
@include colored-style(variant-1-dark, $color-variant-1-dark);
@include colored-style(variant-2, $color-variant-2);
@include colored-style(variant-3, $color-variant-3);

// variant : light
.button {
  &:hover {
    background-color: transparent;
    .label_light {
      color: $color-secondary;
    }
    .iconContainer {
      background-color: $color-light;
    }
    &:hover {
      .icon_only {
        color: $color-secondary;
      }
    }
  }
  &.color_light {
    background-color: $color-light;
    border-color: $color-light;
    &:hover {
      background-color: $color-primary;
      border-color: $color-primary;
      color: $color-secondary;
    }
  }
  .icon_light {
    color: $color-secondary;
  }
  .icon_only {
    color: $color-secondary;
  }
}
