@import "/styles/global/variables.scss";
@import "/styles/global/mixins.scss";

.container {
  position: relative;
  right: 0;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  align-items: flex-end;
  justify-content: center;
  @include media-down(lg) {
    align-items: center;
  }
}

.wrapper {
  display: flex;
  justify-content: space-between;
  column-gap: 16px;
}
