@import "/styles/global/variables.scss";
@import "/styles/global/mixins.scss";

.mainContainer {
  margin-left: $navigation-panel-width;
  margin-top: $menu-top-height;
  padding-top: $progress-bar-height;
  min-height: calc(
    100vh - #{$menu-top-height} - #{$progress-bar-height} - #{$footer-height}
  );
  @include media-down(lg) {
    margin-left: 0;
    min-height: calc(
      100vh - #{$menu-top-height} - #{$progress-bar-height} - #{$footer-mobile-height}
    );
  }
  @media print {
    margin-top: 0;
  }
}
