@import "/styles/global/variables.scss";
@import "/styles/global/mixins.scss";

.container {
  margin-left: auto;
  margin-right: auto;
  width: calc(100% - 2 * #{$container-padding});
  padding-left: $container-padding;
  padding-right: $container-padding;

  @each $breakpoint, $max-width in $container-max-widths {
    @include media-up($breakpoint) {
      max-width: $max-width;
    }
  }
}
