@import "/styles/global/variables.scss";
@import "/styles/global/mixins.scss";

.button {
  position: absolute;
  bottom: 64px;
  right: 80px;
  height: 84px;
  width: 84px;
  background-color: $color-light;
  border-radius: 100%;
  border: 3px solid $color-variant-1;
  outline: none;
  color: $color-variant-1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
  @include media-down(md) {
    display: none;
  }
  @media print {
    display: none;
  }
}
.icon {
  font-size: 32px;
}
