@import "/styles/global/variables.scss";
@import "/styles/global/mixins.scss";

.container {
  @include media-down(lg) {
    margin-top: 0;
  }
  @media print {
    display: none;
    page-break-inside: avoid;
  }
}

.wrapper {
  display: flex;
  align-items: center;
  min-height: 110px;
  position: relative;
  background-color: $color-dark;
  color: $color-variant-6-light;
  @include media-down(lg) {
    height: 350px;
    margin-left: 0;
    padding-right: 24px;
    padding-left: 24px;
  }
}

.subContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 40px;
  text-align: center;
  @include media-down(xl) {
    padding-top: 16px;
    padding-bottom: 16px;
    flex-direction: column;
    row-gap: 30px;
  }
}

.logo {
  display: flex;
  transition: opacity 0.2s;
  &:hover {
    opacity: 0.6;
  }
}

.link {
  color: $color-variant-6-light;
  transition: opacity 0.2s;
  &:hover {
    opacity: 0.6;
  }
}
.button {
  background: none;
  border: none;
  padding: 0;
  text-decoration: underline;
  cursor: pointer;
}
