@import "/styles/global/variables.scss";
@import "/styles/global/mixins.scss";

// Fonts are loaded in /styles/global/fonts.scss

$font-primary: "Open Sans", sans-serif;
$font-secondary: "Quicksand", sans-serif;

.typo {
  font-family: $font-primary;
  a:hover {
    opacity: 0.7;
  }
}
.color {
  &_primary {
    color: $color-primary;
  }
  &_secondary {
    color: $color-secondary;
  }
  &_light {
    color: $color-light;
  }
  &_dark {
    color: $color-dark;
    & a {
      color: $color-dark;
    }
  }
}

.h1 {
  font-weight: 700;
  font-size: 2.25rem;
  font-family: $font-secondary;
}
.h2 {
  font-weight: 700;
  font-size: 1.75rem;
  font-family: $font-secondary;
}
.h3 {
  font-weight: 700;
  font-size: 1.5rem;
  font-family: $font-secondary;
}
.h4 {
  font-weight: 700;
  font-size: 1.25rem;
  font-family: $font-secondary;
}
.h5 {
  font-weight: 700;
  font-size: 1.125rem;
  font-family: $font-secondary;
}
.h6 {
  font-weight: 400;
  font-size: 0.875rem;
}
.body {
  font-size: 1rem;
  line-height: 26px;
}
.button {
  font-weight: 700;
  font-size: 0.875rem;
  font-family: $font-secondary;
}
.socials {
  font-weight: 500;
  font-size: 0.75rem;
  font-family: $font-secondary;
}
.download {
  font-weight: 500;
  font-size: 0.875rem;
  font-family: $font-secondary;
}
.chapter {
  font-weight: 500;
  font-family: $font-secondary;
}
.cta {
  font-weight: 700;
  font-size: 1.125rem;
}
.headline {
  font-size: 1.25rem;
  font-family: $font-secondary;
}
