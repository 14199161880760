@import "/styles/global/variables.scss";
@import "/styles/global/mixins.scss";

.decoration {
  position: absolute;
  top: 96px;
  left: -50px;
  @include media-down(sm) {
    width: 110px;
    left: -30px;
  }
}
.container {
  display: flex;
  justify-content: space-evenly;
  align-items: stretch;
  @include media-down(lg) {
    flex-direction: column;
    align-items: center;
    row-gap: 64px;
  }
  @media print {
    display: block;
  }
}
.download {
  width: 40%;
  @include media-down(lg) {
    width: 80%;
  }
  @include media-down(sm) {
    width: 100%;
  }

  @media print {
    page-break-inside: avoid;
    margin: auto;
    padding: 32px 0;
  }
}
